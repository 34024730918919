import request from '@/router/axios';
import {businessUrl,pvhApiBase} from "@/config/env"

export const list = (pageNo,pageSize,nameOrCode) => {
    return request({
        url : businessUrl + "maintenanceOrder/selectPage",
        method: "post",
        data: {
            pageNo,
            pageSize,
            nameOrCode        }
    })
}
//编辑
export const edit = (row) => {
    return request({
        url : businessUrl + "maintenanceOrder/update",
        method: "put",
        data: {
            ...row
        }
    })
}
//删除
export const remove = (id) => {
    return request({
        url : businessUrl + "maintenanceOrder/delete",
        method: "delete",
        params: {
            id
        }
    })
}
export const changeStatus = (row) => {
    return request({
        url : businessUrl + "maintenanceOrder/updateStatus",
        method: "put",
        data : {
            id : row.id,
            status : Number(row.status)
        }
    })
}
export const add = (row) => {
    return request({
        url : businessUrl + "maintenanceOrder/insert",
        method: "post",
        data : {
            type: 2,
            ...row,
            status: 1
        }
    })
}
export const  selectByPromayKey = (id,auditStatus) => {
    return request({
        url : businessUrl + "maintenanceOrder/selectByPromayKey",
        method: "put",
        data: {
            id,
            auditStatus
        }
    })
}

export const GetSetEmailFunction = (data) => {
    return request({
        url : pvhApiBase + "Tracing/GetSetEmailFunction",
        method: "get",
        params: {
            data
        }
    })
}

export const GetSetEmailFunctions = (data) => {
    return request({
        url : pvhApiBase + "Tracing/GetSetEmailFunctions",
        method: "get",
        params: data
    })
}

export const PostSetEmailFunction = (data) => {
    return request({
        url : pvhApiBase + "Tracing/PostSetEmailFunction",
        method: "post",
        data: data
    })
}

export const DisableEmailContact = (data) => {
    return request({
        url : pvhApiBase + "Tracing/DisableEmailContact ",
        method: "post",
        data: data
    })
}

export const DeleteSetEmailFunction  = (id) => {
    return request({
        url : pvhApiBase + "Tracing/DeleteSetEmailFunction",
        method: "delete",
        params: {
            id
        }
    })
}

export const DeleteEmailContact   = (id) => {
    return request({
        url : pvhApiBase + "Tracing/DeleteEmailContact",
        method: "delete",
        params: {
            id
        }
    })
}

export const PutSetEmailFunction  = (data) => {
    return request({
        url : pvhApiBase + "Tracing/PutSetEmailFunction",
        method: "put",
        data: data
    })
}

export const GetEmailContactListByCorp  = (id) => {
    return request({
        url : pvhApiBase + "Tracing/GetEmailContactListByCorp?corpId=" + id,
        method: "get",
    })
}

export const GetCurrCorporation  = (data) => {
    return request({
        url : pvhApiBase + "ProcessManagement/GetCurrCorporation",
        method: "get",
        params: data
    })
}

export const GetCorpUserListByCurrentCorpId  = (data) => {
    return request({
        url : pvhApiBase + "ProcessManagement/GetCorpUserListByCurrentCorpId",
        method: "get",
        params: data
    })
}

export const PostEmailFunctionUsers  = (data) => {
    return request({
        url : pvhApiBase + "Tracing/PostEmailFunctionUsers",
        method: "post",
        data: data
    })
}

export const PostEmailContact  = (data) => {
    return request({
        url : pvhApiBase + "Tracing/PostEmailContact",
        method: "post",
        data: data
    })
}

export const PutEmailContact  = (data) => {
    return request({
        url : pvhApiBase + "Tracing/PutEmailContact",
        method: "put",
        data: data
    })
}

export const GetEmailContacts  = (data) => {
    return request({
        url : pvhApiBase + "Tracing/GetEmailContacts",
        method: "get",
        params: {
            page: data
        }
    })
}

export const GetCottonBales  = (data) => {
    return request({
        url : pvhApiBase + "Tracing/GetCottonBales",
        method: "get",
        params: data
    })
}

export const BaleTracing = (CorporationId, PickingNo) => {
    return request({
        url: pvhApiBase + "Tracing/GetBaleTracingDetailInfo",
        method: "get",
        params: {
            "corporationId": CorporationId,
            "PickingNo": PickingNo
        }
    })
}
